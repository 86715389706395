import { EventEmitter, Inject, Injectable } from '@angular/core';
import { PusherEvent } from '@app/shared/enums/pusher-event';
import { Tenant } from '@app/shared/interfaces/tenant';
import { ApiService } from '@app/shared/services/api.service';
import { AuthService } from '@app/shared/services/auth.service';
import { Member } from '@app/tenant';
import { environment } from '@environments/environment';
import { API_SERVICE, AUTH_SERVICE } from '@SavandBros/savandbros-ngx-common';
import { captureMessage } from '@sentry/angular-ivy';
import Pusher, { Channel } from 'pusher-js';

@Injectable({ providedIn: 'root' })
export class PusherService {
  /** Pusher client instance. */
  private pusher?: Pusher;

  /** Pusher channel. */
  private channel?: Channel;

  /** Triggers when an event comes from the pusher. */
  public readonly events: Record<PusherEvent, EventEmitter<unknown>> = this.generateEvents();

  constructor(
    @Inject(AUTH_SERVICE) private readonly authService: AuthService,
    @Inject(API_SERVICE) private readonly apiService: ApiService,
  ) {}

  /** @returns value for {@link events}. */
  private generateEvents(): Record<PusherEvent, EventEmitter<unknown>> {
    const events = {} as Record<PusherEvent, EventEmitter<unknown>>;
    for (const event of Object.values(PusherEvent)) {
      events[event] = new EventEmitter();
    }
    return events;
  }

  /** Connect to pusher channel. */
  public connectToChannel(channel: string, info: string): void {
    if (!this.pusher) {
      throw new Error('[PusherService] connectToChannel called when pusher is not set.');
    }
    if (this.channel) {
      this.channel.unbind_all();
      this.channel.unsubscribe();
    }
    this.channel = this.pusher.subscribe(channel);
    this.channel.bind('pusher:subscription_succeeded', (): void => {
      console.debug(`[PusherService] Connected to channel "${info}"`);
    });
    this.channel.bind('pusher:subscription_error', (): void => {
      console.debug(`[PusherService] Failed to connect to channel "${info}"`);
      captureMessage(`[PusherService] Failed to connect to channel "${info}"`);
    });
  }

  /** Start pusher connection. */
  public connect(tenant: Tenant['id'], member: Member['id'], callback: () => void): void {
    this.disconnect();
    this.pusher = new Pusher(environment.pusher.key, {
      wsHost: environment.pusher.host,
      cluster: environment.pusher.cluster,
      channelAuthorization: {
        transport: 'ajax',
        endpoint: `${this.apiService.base}tenants/${tenant}/tenant/members/${member}/websocket-auth/`,
        headers: {
          Authorization: `JWT ${this.authService.access.value}`,
        },
      },
    });
    this.pusher.connection.bind('connected', (): void => {
      console.debug('[PusherService] Connected to pusher');
      callback();
    });
    this.pusher.connection.bind('failed', (): void => {
      console.debug('[PusherService] Failed to connect to pusher');
      captureMessage('[PusherService] Failed to connect to pusher');
    });
    this.pusher.bind_global((event: PusherEvent, data: unknown): void => {
      if (event in this.events) {
        this.events[event].emit(data);
      } else if (!event.includes('pusher')) {
        const message: string = `[PusherService] ${event} was not registered as an emitter.`;
        console.warn(message);
        captureMessage(message);
      }
      console.debug(`[PusherService] ${event}`, data);
    });
  }

  /** Disconnect from all channels, events, and pusher. */
  public disconnect(): void {
    if (!this.pusher) {
      return;
    }
    console.debug(`[PusherService] Disconnecting from pusher (state: ${this.pusher.connection.state})`);
    this.pusher.disconnect();
  }
}
